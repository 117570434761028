import * as React from "react";
import { 
  DataGrid, 
  GridColDef, 
  GridEventListener, 
  GridToolbarContainer, 
  GridToolbarExport, 
  GridToolbarColumnsButton, 
  GridToolbarFilterButton, 
  GridToolbarDensitySelector 
} from '@mui/x-data-grid';
import { queryFullObject,editUser } from "firebaseFunctions/firebase";
import { db } from "firebase-config";
import { useEffect, useState } from "react";
import { onSnapshot } from "firebase/firestore";
import { dbref } from "firebaseFunctions/firebase";
import { Button } from "@mui/material";
import {Typography, FormControlLabel, Switch } from "@mui/material";
import EditGuestModal from "components/admin/EditGuestModal";
import moment from 'moment';

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  { field: "chineseName", headerName: "Chinese name", width: 130 },
  { field: "dateOfBirth", headerName: "D.O.B", width: 130 },
  {
    field: "age",
    headerName: "Age",
    width: 100,
    valueGetter: (params) => {
      const dob = params.row.dateOfBirth;
      if (dob) {
        const age = Math.floor(moment('2024-12-31 23:59:26.123').diff(moment(dob), 'years', true));
        return age;
      }
      return null;
    },
  },
  { field: "nationality", headerName: "Nationality", width: 130 },
  { field: "gender", headerName: "Gender", width: 130 },
  { field: "passportNumber", headerName: "Passport Number", width: 130 },
  { field: "passportExpiry", headerName: "Passport Expiry", width: 130 },
  { field: "contactNumber", headerName: "Contact number", width: 130 },
  { field: "edenBranch", headerName: "Eden Branch", width: 130 },
  { field: "transport", headerName: "Transport", width: 130 },
  { field: "emergencyContact", headerName: "EM-Contact", width: 130 },
  { field: "emergencyContactNumber", headerName: "EM-Contact Number", width: 130 },
  { field: "relationship", headerName: "EM-Contact R/S", width: 130 },
  { field: "specialRequests", headerName: "Special Requests", width: 130 },
  { field: "bookingId", headerName: "Room ID", width: 130 },
  { field: "roomId", headerName: "True Room ID", width: 130 },
 
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport 
        csvOptions={{ 
          fileName: 'campers', 
          delimiter: ',' ,
          utf8WithBom: true
        }} 
      />
    </GridToolbarContainer>
  );
}

export default function UserTable() {
  const [bookingsList, setBookingsList] = useState([]);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editAccount,setEditAccount] = useState({});
  const [editGuest, setEditGuest] = useState({});
  const [showUnpaid, setShowUnpaid] = useState(false);

  const handleToggleChange = (event) => {
    setShowUnpaid(event.target.checked);
  };
  const filteredUsers = showUnpaid
    ? users.filter((user) => !user.hasPaid)
    : users;

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    const bookingIndex = (element) => element.id === params.row.bookingId;
    let bookingObject = bookingsList[bookingsList.findIndex(bookingIndex)];
    const roomIndex = (element) => element.bookingId === params.row.roomId;
    let roomObject =
      bookingObject.rooms[bookingObject.rooms.findIndex(roomIndex)];
    const guestIndex = (element) => element.name === params.row.name;
    let guestObject =
      roomObject.guests[roomObject.guests.findIndex(guestIndex)];
    //tranform guest date values before passing
    guestObject.dateOfBirth =moment(guestObject.dateOfBirth);
    guestObject.passportExpiry=moment(guestObject.passportExpiry);
    
    
    setEditGuest(guestObject);
    setEditAccount(bookingObject);
    setOpen(true);
  };

  const updateUserOnClick = (guest,oldGuest)=>{
   // console.log('work with ',guest,oldGuest)
    const roomIndex = (element) => element.bookingId === oldGuest.roomId;
      editAccount.rooms[editAccount.rooms.findIndex(roomIndex)].guests[oldGuest.roomIndex] = guest;
      const newAccount = editAccount
     editUser(newAccount);
     setOpen(false);
  }

  const handleCloseModal = (event, data) => {
    setOpen(false);
  };

  const processSnapshot = async (snapshot) => {
    let guestList = [];
    let accountsList = [];
  
    snapshot.docs.forEach((doc) => {
      let docObject = doc.data();
      docObject.id = doc.id;
      accountsList.push(docObject);
    });
  
    setBookingsList(accountsList);
  
    accountsList.forEach((booking) => {
      if (booking && booking.rooms && Array.isArray(booking.rooms)) {
        booking.rooms.forEach((room) => {
          if (room && room.guests && Array.isArray(room.guests)) {
            room.guests.forEach((guest, index) => {
              if (guest) {
                guest.hasPaid = room.hasPaid; // Add the hasPaid property to each guest
                guest.roomIndex = index;
                guest.id = guestList.length + 1;
                guest.bookingId = booking.id;
                guest.roomId = room.bookingId;
                guest.dateOfBirth = guest.dateOfBirth && guest.dateOfBirth.seconds
                ? moment(guest.dateOfBirth.seconds * 1000).format('DD MMM YYYY')
                : null;
                guest.passportExpiry = guest.passportExpiry && guest.passportExpiry.seconds
                ? moment(guest.passportExpiry.seconds * 1000).format('DD MMM YYYY')
                : null;
                guestList.push(guest);
              }
            });
          }
        });
      }
    });
  
    setUsers(guestList);
    return users;
  };
  useEffect(() => {
    const unsubscribe = onSnapshot(dbref, (snapshot) => {
      processSnapshot(snapshot);
      
    });

    return () => {
      unsubscribe();
    };
  }, []);
  
  return (
    <div style={{ height: '80vh', width: "100%" }}>
      <FormControlLabel
        control={<Switch checked={showUnpaid} onChange={handleToggleChange} />}
        label="Show Unpaid Guests"
      />
            {showUnpaid && <Typography variant="h1" gutterBottom>UNPAID BOOKINGS</Typography>}

      <DataGrid
        rows={filteredUsers}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        onRowClick={handleRowClick}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      <EditGuestModal
        editGuest={editGuest}
        open={open}
        onCloseModal={handleCloseModal}
        updateUserOnClick={updateUserOnClick}
      ></EditGuestModal>
    </div>
  );
}
